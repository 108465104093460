import React, { Suspense } from "react";
import { Router } from "react-router-dom";
import "./utils/style.css";
import { createBrowserHistory } from "history";
import FullScreenLoading from "components/Layout/FullScreenLoading";

const Layout = React.lazy(() => import("components/Layout"));

export default function App() {
  const history = createBrowserHistory();
  return (  
    <Router history={history}>
      <Suspense fallback={<FullScreenLoading />}>
        <Layout />
      </Suspense>
    </Router>
  );
}
